<template>
  <section>
    <div v-if="!checkEmailTemplateExists()">Please Select Template</div>
    <div v-else-if="!showFields.emailView">
      <div class="size-selector">
        <label for="cardHeight">Card Height:</label>
        <input
          type="range"
          id="cardHeight"
          min="100"
          max="700"
          v-model="cardHeight"
        />
        <span>{{ cardHeight }}px</span>
      </div>

      <div class="card-container">
        <div
          v-for="(dt, index) in getParsedData"
          :key="index"
          class="card"
          :style="{ flex: '0 1 ' + cardWidth + '%' }"
        >
          <el-scrollbar>
            <div v-if="actionButtonFieldKeys && actionButtonFieldKeys.length">
              <div
                v-for="actionKey in actionButtonFieldKeys"
                :key="actionKey"
                class="button-container"
              >
                <ActionButtonExecute
                  v-if="dt.entityData && actionKey.split('#') && actionKey.split('#').length"
                  :data="getField(actionKey, dt._id)"
                  :form="dt.entityData[actionKey.split('#')[0]]"
                  :is-view="false"
                  :isView="false"
                  :workflowDataId="dt._id"
                  :label="getField(actionKey, dt._id).label"
                  :entity_type="currentEntity.entity_type"
                  :entityDataId="dt._id"
                  :entityId="currentEntity._id"
                  :entityData="dt"
                  :checkIsDisabled="false"
                  :selectedTableRows="[]"
                  :fieldsData="
                    getCurrentTemplateFields(actionKey.split('#')[0])
                  "
                  :selfTemplateId="actionKey.split('#')[0]"
                  templateName="templateName"
                  @actionExecuted="refreshCurrentPage"
                />
              </div>
            </div>
            <div
              :style="
                'overflow: scroll;scrollbar-width: thin;max-height:' +
                cardHeight +
                'px;overflow-x: hidden;'
              "
              class="mt-1"
              v-if="dt.ejsTemplate"
              v-html="dt.ejsTemplate"
            ></div>
            <div
              :style="
                'overflow: scroll;scrollbar-width: thin;max-height:' +
                cardHeight +
                'px;overflow-x: hidden;'
              "
              v-else
            >
              No data found
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <div v-else>
      <entity-email-view
        v-if="parsedData"
        :showFields="showFields"
        :isApplicationUserSideValid="isApplicationUserSide"
        :currentMenu="currentMenu"
        :getSelfEntityFilters="getSelfEntityFilters"
        :parsedData="getParsedData"
        :allFieldsObj="allFieldsObj"
        :emailTemplates="allEmailTemplates"
        :keyLabelData="keyLabel"
        @updateRow="updateRow"
        :currentEntity="currentEntity"
        :quickUpdateRowData="quickUpdateRowData"
        :actionButtonFieldKeys="actionButtonFieldKeys"
        :isRelationalDataFetched="isRelationalDataFetched"
        @quickUpdateData="handleQuickUpdateData"
        :search_string="search_string"
        @refreshPage="refreshCurrentPage"
      ></entity-email-view>
    </div>
  </section>
</template>

<script>
import EntityEmailView from "./EntityEmailView.vue";
import contentParserHelper from "@/mixins/contentParserHelper";
import EntitiesHelper from "../../../mixins/EntitiesHelper";
import ActionButtonExecute from "../../templates/formComponentsExecute/ActionButtonExecute.vue";
import { postAPICall } from "../../../helpers/httpHelper";
export default {
  name: "EntityContentView",
  data() {
    return {
      htmlStrings: [
        "<div class='content'>HTML Content 1. This is a long content block that will overflow and require scrolling within the card. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum et ligula in nunc bibendum fringilla a eu lectus.</div>",
        "<div class='content'>HTML Content 2. This content is not too long.</div>",
        "<div class='content'>HTML Content 3. Here is some more text that will need scrolling if the card height is too small.</div>",
        "<div class='content'>HTML Content 4. Short content.</div>",
        "<div class='content'>HTML Content 5. Another block of text that might overflow depending on the height setting. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>",
      ],
      cardWidth: 30,
      allFieldsObj: {},
      cardHeight: 300,
      parsedData: null,
      quickActionModal: false,
      selectedEmailTemplate: this.showFields.selected_email_template,
      actionButtonFieldKeys: [],
      isRelationalDataFetched: false,
    };
  },
  computed: {
    getParsedData() {
      return this.parsedData;
    },
  },
  mixins: [contentParserHelper, EntitiesHelper],
  props: [
    "entityId",
    "search_string",
    "showFields",
    "isApplicationUserSide",
    "currentMenu",
    "getSelfEntityFilters",
    // "selectedEmailTemplate",
    "data",
    "allEmailTemplates",
    "keyLabel",
    "currentEntity",
    "quickUpdateRowData",
  ],
  async mounted() {
    if (!this.selectedEmailTemplate) {
      this.selectedEmailTemplate = this.allEmailTemplates?.[0];
    }
    let currentEmailTemplate = this.allEmailTemplates.find(
      (e) => e._id == this.selectedEmailTemplate
    );

    let allFields = await this.fetchEntityDetails(
      this.currentEntity?._id,
      true,
      false,
      false,
      false,
      true,
      false,
      this.currentEntity
    );
    let childDataTableFields = allFields.filter(e => e.inputType == "ENTITY_TABLE" && e.data_table_field_auto_fill && e.entity_id);
    let selectedEntityIdsForChildTableAutoFill;
    if(childDataTableFields?.length) {
        selectedEntityIdsForChildTableAutoFill = childDataTableFields.map(e => e.entity_id);
    }
    this.allFieldsObj = this.getFieldsObject(allFields, "key");
    let variablesObj;
    [this.parsedData, variablesObj] = this.getParsedTextContent(
      currentEmailTemplate,
      this.data,
      allFields,
      this.currentEntity,
      true
    );
    this.actionButtonFieldKeys = variablesObj["actionButtonFieldKeys"];
    const childDataIds = [
      ...new Set(
        Object.values(variablesObj["childDataTableIdsObj"] || {})?.flat()
      ),
    ];
    const filesArray = [
      ...new Set(Object.values(variablesObj["filesDataObj"] || {})?.flat()),
    ];
    let relationalTables = (
      currentEmailTemplate.relationalTableData || []
    ).filter((table) => table.typee == "relationalTable");
    if (childDataIds.length || relationalTables.length || filesArray.length || selectedEntityIdsForChildTableAutoFill?.length) {
      let entityDataObject = {};
      let replacedChildData = {};
      let responseData = await postAPICall(
        "POST",
        "/entities-data/entity/relationalData",
        {
          childDataIds,
          relationalTableConfig: {
            relationalTables,
            currentPageDataIds: this.parsedData.map((e) => e._id),
          },
          filesArray,
          selectedEntityIdsForChildTableAutoFill,
          entity_id: this.currentEntity._id,
        }
      );
      if (responseData?.data?.childEntityData?.length) {
        (responseData?.data?.childEntityData || []).map((e) => {
          entityDataObject[e._id] = e;
        });
        for (const [key, array] of Object.entries(
          variablesObj["childDataTableIdsObj"]
        )) {
          replacedChildData[key] = array.map(
            (id) => entityDataObject[id] || {}
          );
        }
      }
      let data = await this.replaceAsyncVariables(
        this.parsedData,
        this.allFieldsObj,
        replacedChildData,
        responseData?.data,
        variablesObj,
        currentEmailTemplate,
        this.currentEntity
      );
      if (data.length) {
        this.parsedData = data;
      }
      this.isRelationalDataFetched = true;
    }
    this.handleStyles();
  },
  components: {
    EntityEmailView,
    ActionButtonExecute,
  },
  methods: {
    getCurrentTemplateFields(tempId) {
      return this.currentEntity.templates.find((e) => e.template_id == tempId)
        ?.templateInfo?.sections[0]?.fields;
    },
    getField(actionKey, dataId) {
      let field = this.allFieldsObj[actionKey];
      return {
        ...field,
        parent_entityDataId: dataId,
        parent_entityId: this.currentEntity._id,
        key: field.key.includes("#") ? field.key.split("#")[1] : field.key,
      };
    },
    refreshCurrentPage() {
        this.$emit("refreshCurrentPage");
    },
    handleStyles() {
      if (this.showFields?.columnsPerRow) {
        switch (this.showFields.columnsPerRow) {
          case 1:
            this.cardWidth = 100;
            this.cardHeight = 600;
            break;
          case 2:
            this.cardWidth = 45;
            this.cardHeight = 400;
            break;
          case 3:
            this.cardWidth = 32;
            this.cardHeight = 300;
            break;
        }
      }
    },
    checkEmailTemplateExists() {
      return this.selectedEmailTemplate;
    },
    updateRow(row) {
      this.$emit("updateRow", row);
    },
    handleQuickUpdateData(data) {
      this.$emit("quickUpdateDataProp", data);
    },
  },
  watch: {
    "showFields.columnsPerRow": {
      async handler() {
        // do stuff
        this.handleStyles();
      },
      deep: true,
    },
    "showFields.selected_email_template": {
      async handler() {
        this.selectedEmailTemplate = this.showFields.selected_email_template;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}
.button-container {
  display: flex;
  justify-content: flex-end;
}

.card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 16px;
}

.content {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.size-selector {
  margin-bottom: 16px;
}

.size-selector label {
  font-weight: bold;
  margin-right: 8px;
}

.size-selector input {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .card-container {
    justify-content: center;
  }

  .card {
    flex: 0 1 100%;
  }
}

@media (max-width: 1200px) {
  .card {
    flex: 0 1 48%;
  }
}

@media (min-width: 1201px) {
  .card {
    flex: 0 1 30%;
  }
}
</style>
